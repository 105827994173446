import * as React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function SimpleSnackbar({error, message}) {
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(()=>{
        if(typeof message === "string"){
            setOpen(true)
        }
    },[message])

    const action = (
        <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </React.Fragment>
    );

    return (
        <div>
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={message}
            action={action}
        >
                <Alert 
                    onClose={handleClose} 
                    severity={error? "error" : "success"}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
        </Snackbar>
        </div>
    );
}