import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CategoryForm } from '../../components/forms';
import { deleteCategory, editCategory } from '../../redux/services/Categories';

import { 
    Button,
    Paper,
    Typography,
    Box
} from '@mui/material'
import { SimpleSnackbar } from '../../components/alerts';

export default function Category() {

    const formRef = React.createRef();
    const action = React.useRef("EDIT");
    const {categoryId} = useParams()
    const {data, status} = useSelector( store => store.categories);
    const dispatch = useDispatch();
    const [category, setCategory] = React.useState()


    React.useEffect( () => {   
        const category = data.filter( category => category.id === parseInt(categoryId))[0]
        setCategory(category)
    },[categoryId, data])

    const submitCallback = (data) => {
        const payload = {
            id: categoryId,
            ...data
        }
        switch(action.current){
            case "EDIT": 
                dispatch(editCategory(payload))
                break;
            case "DELETE": 
                dispatch(deleteCategory(payload))
                break;
            default: 
                break;
        }
    }

    const handleSubmit = (event) => {
        action.current = event
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }
    
    return (
        <Box>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Edit category</Typography>
                    <Typography variant={"caption"}>Fill out the form below to add an account</Typography>
                </Box>

                <CategoryForm 
                    ref={formRef} 
                    handleSubmitCallback={submitCallback}
                    defaultValues={category}/>
                <Box sx={{
                    display: "flex", 
                    justifyContent: "flex-end",
                    padding: ".5em",
                    gap: ".5em"        
                    }}>
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("EDIT")}}>Edit</Button> 
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("DELETE")}}>Delete</Button>  
                </Box>               
            </Paper>
            <SimpleSnackbar 
                message={status.message}
                error={status.error}
            />      
        </Box>
    )
}
