import * as React from 'react';
import { Controller } from "react-hook-form";
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    Radio 
} from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const FormRadioButton  = ({ name, control, label, ...props }) => {

    const {
        disabled
        ,options
        ,helpertext
        ,defaultValue
        ,row
    } = props



    
    return (
            <Controller
                name={name}
                control={control}
                render={({ 
                    field: { onChange, onBlur, value, name, ref  }, 
                    fieldState: {error}
                }) => {

                    return (
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
                            <FormHelperText error={error ? true : false}> 
                                {error ? error.message : helpertext}
                            </FormHelperText>
                            <RadioGroup
                                value = {value || defaultValue || ""}  
                                onChange = {onChange}
                                row={row || false}
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                            >
                                {options.map( (option) => (
                                    <FormControlLabel
                                        key={`adio-button-${option.label}`}
                                        value={option.value}
                                        label={option.label}
                                        control={<Radio 
                                            icon={option.icon || <CheckBoxOutlineBlankIcon/>} 
                                            checkedIcon={option.checkedIcon || <CheckBoxIcon/>} 
                                        />}
                                        disabled={disabled || false}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />        
    )
}

export default FormRadioButton;
