import React from 'react'

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Stack,
    Paper,
} from '@mui/material';

import {
    FormInputText,
    FormRadioButton
} from '../fields'

const schema = yup.object({
    name: yup.string().required("Category name is required"),
    description: yup.string().nullable(true),
    estimate: yup.number().required("expenditure is required"),
    categoryType: yup.string().required("Estimate type"),
  });


const CategoryForm = React.forwardRef(({handleSubmitCallback, defaultValues}, ref) =>  {

    
    const { handleSubmit, control, reset} = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        shouldFocusError: true,
        shouldUnregister: false, 
    });

    React.useEffect(() => {
        // reset form with user data
        reset(defaultValues);
    }, [defaultValues, reset]);

    
    return (
            <Paper elevation={0} >
                <form onSubmit={handleSubmit(handleSubmitCallback)} ref={ref}>
                    <Stack gap={2}>
                        <FormInputText 
                            name={"name"} 
                            control={control} 
                            label={"Name"}/>

                        <FormInputText 
                            name={"description"} 
                            control={control} 
                            label={"Description"}/>

                        <FormInputText 
                            name={"estimate"} 
                            control={control} 
                            label={"Monthly estimate"}/>

                        <FormRadioButton 
                            name={"categoryType"} 
                            control={control} 
                            label={"Type"}
                            row={true}
                            options={[
                                {label: "Expenditure", value: "expenditure"},
                                {label: "Income", value: "income"}
                            ]}/>
                    </Stack>                   
                </form>
            </Paper>
    );
});

export default CategoryForm;