import React from 'react'
import { Box, Paper, Typography, Divider, SvgIcon} from '@mui/material'
import { useSelector, dispatch, useDispatch } from 'react-redux'
import MailSuccess from '../icons/MailSuccess.svg';

export default function VerifyAccount() {
  let {email} = useSelector( store => store.user.data.user)
  let {emailVerified} = useSelector( store => store.user.data.authorization)

  return (
    <Box className="frame" sx={{
        backgroundColor: "fourthly.main",
        height: "calc(100vh - 64px)",
        width: "100%",
        position: "relative",
        p: 0,
        m: 0,
    }}>
        <Paper className="container" sx={{
            p: 5,
            position: "absolute",
            width: "fit-content",
            blockSize: "fit-content",
            top: "50%",
            left: "50%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            gap: 1,
            transform: "translate(-50%, -50%)",            
        }}>
            <img 
              src={MailSuccess} 
              alt="React Logo" 
              height="180px"
            />
            <Typography variant="h4" noWrap={false}>Welcome to BazzPara</Typography>
            <Divider/>
            <Typography variant="caption" noWrap={false}>
              {emailVerified
               ? (`Your account is successfully created and verified. You can now use the application as intended`)
               : (`Congratulations your account is now created. To start using the application please follow the link sent to ${email}, in order to verify the account`)
              }
            
            </Typography>
        </Paper>
    </Box>
  )
}
