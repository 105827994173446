import React from 'react'
import _ from "lodash"
import {TableContainer
        ,Table
        ,TableHead
        ,TableBody
        ,TableRow
        ,TableCell
        ,Paper
        ,Button
    } from "@mui/material"

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


export default function TransactionTable() {

    const transactions = useSelector((state) => state.transactions.data)
    const navigate = useNavigate();

    const handleClick = (e)=>{
        navigate(`/transactions/${e.currentTarget.value}`)
    }

    return (
        <TableContainer component={Paper} sx={{height: "100%"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.orderBy(transactions, "transactionDate", "desc").map( (transaction, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{transaction["description"]}</TableCell>
                            <TableCell>{ moment(transaction["transactionDate"]).format('YY/MM/DD')}</TableCell>
                            <TableCell>{transaction["amount"]}</TableCell>
                            <TableCell>{transaction["category"]["name"]}</TableCell>
                            <TableCell>{transaction["account"]["name"]}</TableCell>
                            <TableCell>
                                <Button 
                                    value={transaction["id"]} 
                                    onClick={handleClick}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                
            </Table>
        </TableContainer>
    )
}
