import React from 'react'

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Stack,
    Paper,
} from '@mui/material';

import {
    FormInputText,
    FormRadioButton,
    FormDatePicker,
    FormDropdown,
} from '../fields'
import { useSelector } from 'react-redux';

const schema = yup.object({
    transactionDate : yup.date("Wrong format").required("Date is required"),
    description : yup.string("Wrong format").required("Description is required"),
    amount: yup.number("Wrong format").required("Amount"),
    transactionType: yup.string("Wrong format").required("Transaction type"),
    accountId: yup.number("Wrong format").required("Estimate type"),
    categoryId: yup.number("Wrong format").nullable(true),
  });


const CategoryForm = React.forwardRef(({handleSubmitCallback, defaultValues}, ref) =>  {

    const accounts = useSelector( state => state.accounts.data)
    const categories = useSelector( state => state.categories.data)

    const { handleSubmit, control, reset} = useForm({
        defaultValues: defaultValues, 
        resolver: yupResolver(schema),
        shouldFocusError: true,
        shouldUnregister: false, 
    });

    React.useEffect(() => {
        // reset form with user data
        reset(defaultValues);
    }, [defaultValues, reset]);

    
    return (
            <Paper elevation={0} >
                <form onSubmit={handleSubmit(handleSubmitCallback)} ref={ref}>
                    <Stack gap={2}>

                        <FormInputText 
                            name={"description"} 
                            control={control} 
                            label={"Description"}/>

                        <Stack gap={2} direction="row">
                            <FormDropdown 
                                name={"categoryId"} 
                                control={control} 
                                label={"Categories"} 
                                options={categories.map( (e) => ({"label" : e.name, "value" : e.id}))}/>

                            <FormDropdown 
                                name={"accountId"} 
                                control={control} 
                                label={"Account"} 
                                options={accounts.map( (e) => ({"label" : e.name, "value" : e.id}))}/>
                        </Stack>


                        <Stack gap={2} direction="row">
                            <FormInputText 
                                name={"amount"} 
                                control={control} 
                                label={"Amount"}/>

                            <FormDatePicker 
                                name={"transactionDate"} 
                                control={control} 
                                label={"Date"} />

                        </Stack>

                        <FormRadioButton 
                                name={"transactionType"} 
                                control={control} 
                                label={"Type"}
                                defaultValue={"expenditure"}
                                row={true}
                                options={[
                                    {label: "Expenditure", value: "expenditure"},
                                    {label: "Income", value: "income"}
                                ]}/>
                    </Stack>                   
                </form>
            </Paper>
    );
});

export default CategoryForm;