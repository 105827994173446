import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import { addCategory } from '../../redux/services/Categories';

import { 
    Button,
    ButtonGroup,
    Paper,
    Typography,
    Box
} from '@mui/material'

import { CategoryForm } from '../../components/forms';
import { CategoryTable } from '../../components/tables';
import { SimpleSnackbar } from '../../components/alerts';

export default function Categories() {
    const formRef = React.createRef()
    const dispatch = useDispatch();
    const {error, message} = useSelector( store => store.categories.status)

    const submitCallback = (data) => {
        dispatch(addCategory(data))
    }

    const handleSubmit = () => {
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }

    
    return (
        <Box>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Add category</Typography>
                    <Typography variant={"caption"}>Fill out the form below to add a category</Typography>
                </Box>

                <CategoryForm 
                    ref={formRef} 
                    handleSubmitCallback={submitCallback}
                    defaultValues={{
                        categoryType : "expenditure"
                    }}/>
                <ButtonGroup sx={{
                    display: "flex", 
                    justifyContent: "flex-end",
                    padding: ".5em"          
                    }}>
                    <Button color="secondary" variant="contained" onClick={handleSubmit}>submit</Button> 
                </ButtonGroup>               
            </Paper>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Your categories</Typography>
                    <Typography variant={"caption"}>The table below provides an overview over your current categories</Typography>
                </Box>
                <CategoryTable/>            
            </Paper>
            <SimpleSnackbar 
                message={message}
                error={error}
            />   
        </Box>
    )
}
