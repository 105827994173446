import React from 'react'
import _ from "lodash";  
import { getColor } from '../../util/color';
import { getPeriodForDate } from '../../util/date';
import { useSelector } from 'react-redux';

import {Paper, Typography, Box} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
  CartesianGrid
} from "recharts";



export default function ExpenditurePerCategory() {
  const {endDate, startDate, datePeriod, dateGranuality} = useSelector(state => state.settings.data.filterOptions);
  const transactions = useSelector(state => state.transactions.data);
  const categories = useSelector(state => state.categories.data);
  const [activeBarLabel, setActiveBarLabel] = React.useState(null)
  const [data, setDate] = React.useState([])


  const CustomTooltip = ({ active, payload, label }) => {
    if ( !(active && payload && payload.length) ){return}

    const idx = _.indexOf(datePeriod, label);
    const period = ((idx + 1) === datePeriod.length) 
      ? label+" - "+endDate 
      : label+" - "+datePeriod[idx + 1]
    let bar = _.find(payload, {"name" : activeBarLabel})

    return (
      <Paper className="custom-tooltip" sx={{
        boxSizing: "border-box",
        p: 1,
      }}>
        <Typography className="label">{`Period: ${period}`}</Typography>
        <Typography className="desc">{`Category: ${activeBarLabel}`}</Typography>
        <Typography className="desc">{`Amount: ${bar? bar.value :0}`}</Typography>
      </Paper>
    )
  }

  React.useEffect(() => {
    const result = datePeriod.map( dt => ({date: dt}))

    transactions.forEach( ({transactionType, transactionDate, amount, category}) => {
      if(new Date(transactionDate) <= new Date(endDate) 
        && new Date(transactionDate) >= new Date(startDate)
        && transactionType === "expenditure"
      ){
        let transactionPeriod = getPeriodForDate(transactionDate, datePeriod).format("YYYY/MM/DD")
        let dataset = _.find(result,  { 'date': transactionPeriod}) || "Uknown";

        dataset[category.name] = (dataset.hasOwnProperty(category.name))
          ? dataset[category.name] + Math.abs(amount)
          : Math.abs(amount)
      }})

      setDate(result)
  }, [transactions, startDate, endDate, datePeriod])

  return (
    <Box sx={{
      flexGrow: "1",
      maxWidth: "500px",
      maxHeight: "300px",
    }}>
    <Typography variant={"h6"} align="center">Expenditure per {dateGranuality}</Typography>
    <ResponsiveContainer
      width={"100%"} 
      height={"100%"}
      minHeight={100}
      minWidth={100}
      >
      <BarChart
        data={data}
        margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
      >

        <Tooltip cursor={false} content={<CustomTooltip />}/>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" height={40}>
          <Label value="Start of period" offset={0} position="insideBottom" />
        </XAxis>
        <YAxis />
        {categories.map( (c,i) => (
          <Bar 
            dataKey={c.name} 
            key={i} 
            stackId={"a"} 
            fill={getColor(c.name)}
            onMouseEnter={() => setActiveBarLabel(c.name)}
            onMouseLeave={() => setActiveBarLabel(null)}
          />
        ))}
      </BarChart>
      </ResponsiveContainer>
      </Box>      
  )
}

// 