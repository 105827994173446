import React from 'react'
import _ from "lodash";  
import { getColor } from '../../util/color';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Label,
  ResponsiveContainer,
  CartesianGrid
} from "recharts";

import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Typography } from '@mui/material';



export default function MoneyPerCategoryLeft() {
  const {endDate, startDate, datePeriod} = useSelector(state => state.settings.data.filterOptions);
  const transactions = useSelector(state => state.transactions.data);
  const categories = useSelector(state => state.categories.data);

  const [data, setData] = React.useState([])


  React.useEffect(() => {
    const periodLength = moment(startDate, "YYYY/MM/DD").diff(moment(endDate, "YYYY/MM/DD"), 'days')
    
    // Go through categories
    let result = categories.map( ({name, estimate, categoryType}) => ({
      name: name,
      value: Math.round(Math.abs((parseInt(estimate) / 30.5) * periodLength)),
      type: categoryType,
      label: name
    }))

    // Go through transactions
    transactions
      .filter( ({transactionDate}) => (new Date(transactionDate) <= new Date(endDate) && new Date(transactionDate) >= new Date(startDate))) 
      .forEach( ({amount, category}) => {
        let obj = _.find(result, {'name': category.name})

        // Guard clause
        if(typeof obj === 'undefined' || obj === null){return}

        obj["value"] -= Math.abs(parseInt(amount))
      })

    // Divide transactions in expenses and income based on type
    let expenditrues = result.filter( ({type}) => type === "expenditure")

    setData(expenditrues)

  }, [transactions, startDate, endDate, datePeriod, categories])

  return (
    <Box sx={{
      flexGrow: "1",
      maxWidth: "500px",
      maxHeight: "300px",
    }}>
      <Typography variant={"h6"} align="center">Money left for period</Typography>
      <ResponsiveContainer 
        width={"100%"} 
        height={"100%"}
        minHeight={100}
        minWidth={100}
        >
        <BarChart 
          data={data}
          margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
        >
            
          <XAxis dataKey="name" tick={false} height={40}>
            <Label value="Categories" offset={0} position="insideBottom" />
          </XAxis>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip cursor={false}/>
          <Bar dataKey={"value"} fill={"name"}>
            {data.map(({name}, index) => (
              <Cell key={`cell-${index}`} fill={getColor(name)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
  
}

// 