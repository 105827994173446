import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TransactionForm } from '../../components/forms';
import { editTransaction, deleteTransaction} from '../../redux/services/Transactions';

import { 
    Button,
    Paper,
    Typography,
    Box
} from '@mui/material'
import { SimpleSnackbar } from '../../components/alerts';

export default function Transaction() {

    const formRef = React.createRef();
    const action = React.useRef("EDIT");
    const {transactionId} = useParams()
    const {data, status} = useSelector( store => store.transactions);
    const dispatch = useDispatch();
    const [transaction, setTransaction] = React.useState({})


    React.useEffect( () => {   
        const transaction = data
          .filter( transaction => transaction.id === String(transactionId))
          .map( transaction => ({
            transactionDate : transaction.transactionDate,
            description : transaction.description,
            amount: transaction.amount,
            transactionType: transaction.transactionType,
            accountId: transaction.account.id,
            categoryId: transaction.category.id    
          }))[0]
        setTransaction(transaction)
    },[data, transactionId])

    const submitCallback = (data) => {
        const payload = {
            id: transactionId,
            ...data
        }

        switch(action.current){
            case "EDIT": 
                dispatch(editTransaction(payload))
                break;
            case "DELETE": 
                dispatch(deleteTransaction(payload))
                break;
            default: 
                break;
        }
    }

    const handleSubmit = (event) => {
        action.current = event
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }
    
    return (
        <Box>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Edit Transaction</Typography>
                    <Typography variant={"caption"}>Fill out the form below to add a transaction</Typography>
                </Box>

                <TransactionForm 
                    ref={formRef} 
                    handleSubmitCallback={submitCallback}
                    defaultValues={transaction}/>
                <Box 
                  sx={{
                    display: "flex", 
                    justifyContent: "flex-end",
                    padding: ".5em",
                    gap: ".5em"        
                }}>
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("EDIT")}}>Edit</Button> 
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("DELETE")}}>Delete</Button>  
                </Box>               
            </Paper>
            <SimpleSnackbar 
                message={status.message}
                error={status.error}
            />      
        </Box>
    )
}
