import React from 'react'
import { useForm } from "react-hook-form";

import { 
    Button,
    ButtonGroup,
    Paper,
    Typography,
    Box,
    MenuItem,
    InputLabel,
    FormControl,
    Select

} from '@mui/material'

import {TransactionForm} from '../../components/forms'
import {TransactionTable} from '../../components/tables'
import {addTransaction} from '../../redux/services/Transactions'
import {useDispatch, useSelector} from 'react-redux'
import { SimpleSnackbar } from '../../components/alerts'

export default function Transactions() {
    const formRef = React.createRef()
    const [defaultValue, setdefaultValue] = React.useState({
        transactionType : "expenditure",
        transactionDate: new Date()
    })
    const dispatch = useDispatch();
    const {status} = useSelector( store => store.transactions);
    const recurringTransactions = useSelector( state => state.recurringTransactions.data)

    const handleChange = (event) => {
        const transaction = recurringTransactions.filter( ({id}) => (id == event.target.value))[0]
        transaction && setdefaultValue({
            ...defaultValue,
            description: transaction.description,
            amount: transaction.amount,
            transactionType: transaction.transactionType,
            accountId: transaction.account.id,
            categoryId: transaction.category.id
        })
    }

    const handleSubmit = (data) => {
        dispatch(addTransaction(data));   
    }

    const submit = () => {
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }



    return (
      <Box>
        <Paper 
            elevation = {1}
            sx={{
                padding: ".5em",
                margin: "auto",
                width: { 
                    xs: "100%", 
                    sm: "100%",
                    md: "100%",
                    lg: "50%"
                }
        }}> 
            <Box sx={{
                paddingBottom: ".5em"
            }}>
                <Typography variant={"h3"}>Add transaction</Typography>
                <Typography variant={"caption"}>Fill out the form below to add a Transaction</Typography>
            </Box>

            <TransactionForm 
                ref={formRef} 
                handleSubmitCallback={handleSubmit}
                defaultValues={defaultValue}/>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Saved transactions</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Saved transactions"
                    onChange={handleChange}
                    value=""
                >
                    <MenuItem key={-1} value="">
                        <em>None</em>
                    </MenuItem>
                    {recurringTransactions.map( (transactions, idx) => (
                        <MenuItem 
                            key={idx}
                            value={transactions.id}>{transactions.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ButtonGroup sx={{
                display: "flex", 
                justifyContent: "flex-end",
                padding: ".5em"          
                }}>
                <Button color="secondary" variant="contained" onClick={submit}>submit</Button> 
            </ButtonGroup>               
        </Paper>
          <Paper 
            elevation = {1}
            sx={{
                padding: ".5em",
                margin: "auto",
                width: { 
                    xs: "100%", 
                    sm: "100%",
                    md: "100%",
                    lg: "50%"
                }
          }}> 
            <Box sx={{
                paddingBottom: ".5em"
            }}>
                <Typography variant={"h3"}>Your transactions</Typography>
                <Typography variant={"caption"}>The table below provides an overview over your current transactions</Typography>
            </Box>
            <TransactionTable/>            
        </Paper>
        <SimpleSnackbar 
                message={status.message}
                error={status.error}
            /> 
      </Box>
    )
}
 