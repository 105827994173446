import moment from "moment"

function getDate(date,format){
    if(format === "year"){
        return moment(date).format("YYYY")
    }

    else if(format === "month") {
        return moment(date).format("YYYY MMM")
    }

    else if(format === "week") {
        return moment(date).format("YYYY W")
    }

    else{
        return moment(date).format("YYYY-MM-DD")
    }
}

const getDatesForPeriod = function({startDate, endDate, step} = {}) {
    // initialize default values
    var step = step? step : "month"
    var now = startDate? startDate.clone() : moment().subtract(1, step)
    var endDate = endDate? endDate : moment();
    
    var dates = [];

    // Add dates based on stepsize untill end date is reached
    while (now.isSameOrBefore(endDate)) {   
        dates.push(now.format("YYYY/MM/DD"));  
        now.add(1, step);   
    }

    return dates;
};

const getPeriodForDate = function (date, period){

    var date = moment(date, "YYYY/MM/DD")
    var values = [...period]
    var currentDate = moment(values.shift(), "YYYY/MM/DD");


    while (date.isSameOrAfter(currentDate)) { 
        if(values.length == 0){
            return currentDate
        }
        var previousDate = currentDate
        currentDate = moment(values.shift());
    }

    return previousDate

}



export {getDate, getDatesForPeriod, getPeriodForDate}