import React from 'react'

import { 
    Button,
    ButtonGroup,
    Paper,
    Typography,
    Box
} from '@mui/material'

import { AccountForm } from '../../components/forms'

import { useDispatch, useSelector } from 'react-redux'
import { addAccount } from '../../redux/services/Accounts'

import {AccountTable} from '../../components/tables'
import {SimpleSnackbar} from '../../components/alerts'

export default function Accounts() {
  const formRef = React.createRef()
  const dispatch = useDispatch();
  const {error, message} = useSelector(store => store.accounts.status)


  const submitCallback = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      balance: data.balance
    }
    
    dispatch(addAccount(payload))
  }

  const handleSubmit = () => {
      formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
  }


  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <Paper 
        elevation = {1}
        sx={{
            padding: ".5em",
            margin: "auto",
            width: { 
                xs: "100%", 
                sm: "100%",
                md: "100%",
                lg: "50%"
            }
      }}> 
        <Box sx={{
          paddingBottom: ".5em"
        }}>
          <Typography variant={"h3"}>Add account</Typography>
          <Typography variant={"caption"}>Fill out the form below to add an account</Typography>
        </Box>

        <AccountForm ref={formRef} handleSubmitCallback={submitCallback}/>
        <ButtonGroup sx={{
          display: "flex", 
          justifyContent: "flex-end",
          padding: ".5em"          
        }}>
          <Button color="secondary" variant="contained" onClick={handleSubmit}>submit</Button> 
        </ButtonGroup>               
      </Paper>

      <Paper 
        elevation = {1}
        sx={{
          padding: ".5em",
          margin: "auto",
          width: { 
              xs: "100%", 
              sm: "100%",
              md: "100%",
              lg: "50%"
          }
      }}> 
        <Box sx={{
          paddingBottom: ".5em"
        }}>
          <Typography variant={"h3"}>Your accounts</Typography>
          <Typography variant={"caption"}>Fill out the form below to add an account</Typography>
        </Box>
          <AccountTable/>            
      </Paper>
      <SimpleSnackbar 
        message={message}
        error={error}
      />      
    </Box>
  )
}

