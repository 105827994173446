import React from 'react'

import { 
    Button,
    Paper,
    Typography,
    Box
} from '@mui/material'

import AccountForm from '../../components/forms/AccountForm'
import { useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom';
import { deleteAccount, editAccount } from '../../redux/services/Accounts';
import { SimpleSnackbar } from '../../components/alerts';


export default function Account() {
    const formRef = React.createRef();
    const action = React.useRef("EDIT");
    const {accountId} = useParams()
    const {data, status} = useSelector( store => store.accounts);
    const dispatch = useDispatch();
    const [account, setAccount] = React.useState()



    React.useEffect( () => {      
        const account = data.filter( account => account.id === parseInt(accountId))[0]
        setAccount(account)
    },[accountId, data])


    const submitCallback = (data) => {
        const payload = {
            id: accountId,
            ...data
        }

        switch(action.current){
            case "EDIT": 
                dispatch(editAccount(payload))
                break;
            case "DELETE": 
                dispatch(deleteAccount(payload))
                break;
            default: 
                break;
        }
    }

    const handleSubmit = (event) => {
        action.current = event
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }




    return (
        <Box>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Edit account</Typography>
                    <Typography variant={"caption"}>Fill out the form below to add an account</Typography>
                </Box>

                <AccountForm 
                    ref={formRef} 
                    handleSubmitCallback={submitCallback}
                    defaultValues={account}/>
                <Box sx={{
                    display: "flex", 
                    justifyContent: "flex-end",
                    padding: ".5em",
                    gap: ".5em"        
                    }}>
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("EDIT")}}>Edit</Button> 
                    <Button color="secondary" variant="contained" onClick={() => {handleSubmit("DELETE")}}>Delete</Button>  
                </Box>               
        </Paper>
        <SimpleSnackbar 
            message={status.message}
            error={status.error}
        />   
    </Box>
    )
}
 