import axios from 'axios';



const api = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN,
	timeout: 5000,
	headers: {
		accept: 'application/json',
	}, 
});

export default api;