import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout, loginWithPopup } from '../../redux/services/User';
import { 
    Box
    ,Typography
    ,AppBar
    ,Toolbar
    ,Stack 
    ,Button
    } from '@mui/material'

import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

export default function MainNavbar() {
  const {loggedIn, emailVerified} = useSelector((state) => state.user.data.authorization)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    dispatch(logout())
  }

  const handleLogin = async () => {
    dispatch(loginWithPopup())
  }

  const handleRedirect = (e) => {
    navigate(e.target.value)
  }

  return (
      <Box className="navbar" sx={{
        height:"64px",
        width: "100vw",
        boxSizing: "border-box",
        m: 0,
      }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "primary"}}>
              Bazzpara
            </Typography>

              { (loggedIn)
                ?(
                  <Box sx={{ display: 'flex', flexgrow: 1, gap: 2}}>
                    {ROUTES.filter( (link) => link.includedInNav).map( (link, id) => (
                        <Button color="secondary" variant="contained" key={id} value={link.path} onClick={handleRedirect}>{link.verbose}</Button>
                    ))}  
                    <Button color="secondary" variant="contained" onClick={handleLogout}>Logout</Button>
                  </Box>                            
                )
                :(
                  <Box sx={{ display: 'flex', flexgrow: 1, gap: 2}}>
                    <Button color="secondary" variant="contained" value={"/login"} onClick={handleLogin}>Login</Button>
                  </Box>
                )
              }   
               
          </Toolbar>
        </AppBar>
      </Box>
    );
}
