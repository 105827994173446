import api from "./Axios";

import { 
    addCategory as addCategoryReducer,
    deleteCategory as deleteCategoryReducer,
    modifyCategory as modifyCategoryReducer,
    setCategories as setCategoriesReducer,
    resetStatus,
    setStatus,
} from "../reducers/Categories";
import { setTransactions } from "../reducers/Transactions";
import { setRecurringTransactions } from "../reducers/RecurringTransactions";



function getCategories() { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "get",
            url: "categories",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`}
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data  
        .then( (response) => {

            const payload = {
                data: response.data.map( (category) => ({
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    categoryType: category.category_type,
                    estimate: Math.round((category.estimate) * 30.5)
                })),

                status: {
                    error: false,
                    message: "Successfully retrieved categories"
                }
            }

            dispatch(setCategoriesReducer(payload))
        })

        // Distpatch error
        .catch( (error) => {

            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function addCategory(payload) { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "POST",
            url: "categories",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                categoryType: payload.categoryType,
                estimate: payload.categoryType === "expenditure"
                    ? ( -1 * Math.abs(payload.estimate) / 30.5)
                    : ( +1 * Math.abs(payload.estimate) / 30.5),
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: response.data.map( (category) => ({
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    categoryType: category.category_type,                 
                    estimate: category.category_type === "expenditure"
                        ? Math.floor( -1 * Math.abs(category.estimate) * 30.5)
                        : Math.floor( +1 * Math.abs(category.estimate) * 30.5),
                }))[0],
                status: {
                    error: false,
                    message: "Successfully added a category"
                }
            }

            dispatch(addCategoryReducer(payload))
        })

        // Distpatch error
        .catch( ({response}) => {
            
            const payload = {
                error: true,
                message: response.data.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function editCategory(payload) { 
    return async (dispatch, getState ) => {
        const {user, transactions, recurringTransactions} = getState()

        const request = {
            method: "PUT",
            url: `categories/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                estimate: payload.categoryType === "expenditure"
                    ? ( -1 * Math.abs(payload.estimate) / 30.5)
                    : ( +1 * Math.abs(payload.estimate) / 30.5),
                categoryType: payload.categoryType
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: response.data.map( (category) => ({
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    categoryType: category.category_type,
                    estimate: category.category_type === "expenditure"
                        ? Math.floor( -1 * Math.abs(category.estimate) * 30.5)
                        : Math.floor( +1 * Math.abs(category.estimate) * 30.5),
                }))[0],
                status: {
                    error: false,
                    message: "Successfully modified a category"
                }
            }

            dispatch(modifyCategoryReducer(payload))
            return payload.data
        })

        // Dispatch: modify transactions
        .then( response => {
        
            const payload = {
                data: transactions.data.map( transaction => {
                    if(transaction.category.id === response.id){
                        return ({
                            ...transaction,
                            category: {
                                name: response.name,
                                id: response.id,
                                estimate: response.estimate,  
                                categoryType: response.categoryType    
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    message: "Successfully updated transactions",
                    error: false
                }
            }

            dispatch(setTransactions(payload))
            return response;
        })
        
        // Dispatch: modify recurring transactions
        .then( response => {
            const payload = {
                data: recurringTransactions.data.map( transaction => {
                    if(transaction.category.id === response.id){
                        return ({
                            ...transaction,
                            category: {
                                name: response.name,
                                id: response.id,
                                estimate: response.estimate,  
                                categoryType: response.categoryType  
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    message: "Successfully updated recurring transactions",
                    error: false
                }
            }
        

            dispatch(setRecurringTransactions(payload))
        })

        // Distpatch error
        .catch( ({response}) => {
            
            const payload = {
                error: true,
                message: response.data.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function deleteCategory(payload) { 
    return async (dispatch, getState ) => {
        const {user, transactions, recurringTransactions} = getState()

        const request = {
            method: "DELETE",
            url: `categories/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: {
                    id: response.data[0].id,
                },   
                status: {
                    error: false,
                    message: "Successfully modified a category"
                }
            }

            dispatch(deleteCategoryReducer(payload))
            return payload.data
        })

        // Dispatch: modify transactions
        .then( response => {

            const payload = {
                data: transactions.data.map( transaction => {
                    if(transaction.category.id === response.id){
                        return ({
                            ...transaction,
                            category: {
                                name: null,
                                id: null,
                                estimate: null,  
                                categoryType: null 
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    message: "Successfully updated transactions",
                    error: false
                }
            }
            

            dispatch(setTransactions(payload))
            return response;
        })

        // Dispatch: modify recurring transactions
        .then( response => {

            const payload = {
                data: recurringTransactions.data.map( transaction => {
                    if(transaction.category.id === response.id){
                        return ({
                            ...transaction,
                            category: {
                                name: null,
                                id: null,
                                estimate: null,  
                                categoryType: null 
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    message: "Successfully updated recurring transactions",
                    error: false
                }
            }
            

            dispatch(setRecurringTransactions(payload))
        })

        // Distpatch error
        .catch( (error) => {
            
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}




export {getCategories, addCategory, editCategory, deleteCategory}