import React from 'react'
import {Box, Link, Typography} from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function SimpleFooter() {
  return (
    <Box sx={{
        boxSizing: "border-box",
        width: "100%",
        hieght: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary.main",
        gap: 1,
        px: 2,
        py: 1,
    }}
    >
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 2   
        }}>
            <Link color="secondary" underline="hover" href="/about">About</Link>
            <Link color="secondary" underline="hover" href="/privacy">Privacy policy</Link>
            <Link color="secondary" underline="hover" href="/contact">Contact</Link>           
        </Box>

        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 1
        }}>
            <FacebookIcon/>
            <TwitterIcon/>
            <InstagramIcon/>
        </Box>

    </Box>
  )
}
