import api from "./Axios";
import { getDatesForPeriod } from "../../util/date";

import { 
    setFilterOptions,
    resetStatus,
    setStatus,
} from "../reducers/Settings";
import moment from "moment";


function changeDatePeriod(payload){
    return async (dispatch, _ ) => {

        const period = getDatesForPeriod({
            startDate: payload.startDate,
            endDate: payload.endDate,
            step: payload.dateGranuality,
        })
       
        const result = {
            filterOptions: {
                startDate: payload.startDate.format("YYYY-MM-DD"),
                endDate: payload.endDate.format("YYYY-MM-DD"),
                dateGranuality: payload.dateGranuality,
                datePeriod: period
            },
            status: {
                error: false,
                message: "modified date"
            }
        }

        dispatch(setFilterOptions(result))
    }
}



export {changeDatePeriod}