import React from 'react'
import {Paper, Typography, Box} from '@mui/material' 
import { styled } from '@mui/system'

const iconWidth = "50px"
const iconHeight = "50px"




const Icon = styled("div")(({theme}) => ({
    zIndex: "2",
    position: "relative",
    margin: "auto",
    boxSizing: "border-box",
    width: `${iconWidth}`,
    height: `${iconHeight}`,
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    "& .MuiSvgIcon-root": {
        width: "100%",
        height: "100%"
    }
}));

const Content = styled(Paper)(({theme}) => ({
    zIndex: "1",
    position: "relative",
    bottom: `calc(${iconWidth} / 2)`,
    padding: `calc(${iconWidth} / 2)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
}));





export default function PapperWithIcon({children, icon, header, caption, sx}) {
  return (
    <Box sx={{textAlign: "center",...sx}}>
        <Icon>
            {icon}
        </Icon>
        <Content>
            <Typography display="block" variant="h4" noWrap={false}>
                {header}
            </Typography>
            <Typography variant="caption">
                {caption}
            </Typography>
            {children}
        </Content>
    </Box>
  )
}


