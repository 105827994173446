import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getAccessToken, silentAuthentication} from '../../redux/services/User';

export default function AuthWrapper({children}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loggedIn} = useSelector( store => store.user.data.authorization);
  const [loaded, setLoaded] = useState(false);
  const appState = React.useRef({});

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);

    let code = params.get("code");
    let state = params.get("state"); 
    let error = params.get("error");

    appState.current = state
    
    if(state){
      
      if(code){
        dispatch(getAccessToken(code,state))
      } 

      else if(error){
        if(error === "login_required"){
          //navigate(window.location.path)
        }

        else if(error === "consent_required"){
          //navigate(window.location.path)
        }

        else if(error === "interaction_required"){
          //navigate(window.location.path)
        }
      }
      setLoaded(true)
      
    }

    else {
      dispatch(silentAuthentication())
    }

    navigate(window.location.path)
  },[])



  useEffect( () => {
    let state = localStorage.getItem(appState.current)
    if(!state && !loggedIn){ return }
    state = JSON.parse(state)
    
    if(state.hasOwnProperty("return_uri")){
      navigate(state.return_uri)
    }
      
  }, [loggedIn])



  return (
    <>
      {!Boolean(loaded) || (children)}
    </>
  )
}