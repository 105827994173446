import { Button, Paper, Typography } from '@mui/material' 
import { styled } from '@mui/system'
import React from 'react'


const BannerImage = styled("div")(({theme, backgroundImage}) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2)
}));

const BannerContainer = styled(Paper)(({theme}) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  background: theme.palette.common.white,
  padding: theme.spacing(2),
  width: "400px",
}));

export default function BannerWithBox({backgroundImage, header, caption, actionText, onClick, sx}) {
  return (
   <BannerImage sx={{...sx}} backgroundImage={backgroundImage}>
     <BannerContainer elevation={1}>
      <Typography display="block" variant="h4" noWrap={false}> 
          {header}
      </Typography>
              
      <Typography variant="caption">
          {caption}
      </Typography>

      <Button color="secondary" variant="contained" onClick={onClick}>
          {actionText}
      </Button>
     </BannerContainer>
   </BannerImage>

  )
}
