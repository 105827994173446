import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import { addRecurringTransaction } from '../../redux/services/RecurringTransactions';

import { 
    Button,
    ButtonGroup,
    Paper,
    Typography,
    Box
} from '@mui/material'

import { RecurringTransactionsTable } from '../../components/tables';
import { RecurringTransactionForm } from '../../components/forms';
import { SimpleSnackbar } from '../../components/alerts';

export default function RecurringTransactions() {
    const formRef = React.createRef()
    const dispatch = useDispatch();
    const {error, message} = useSelector( store => store.categories.status)

    const submitCallback = (data) => {
        dispatch(addRecurringTransaction(data))
    }

    const handleSubmit = () => {
        formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    }

    
    return (
        <Box>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Add recurring transactions</Typography>
                    <Typography variant={"caption"}>Fill out the form below to add a recuring transaction</Typography>
                </Box>

                <RecurringTransactionForm 
                    ref={formRef} 
                    handleSubmitCallback={submitCallback}
                    defaultValues={{
                        transactionType : "expenditure",
                        transactionDate: new Date(),
                }}/>
                <ButtonGroup sx={{
                    display: "flex", 
                    justifyContent: "flex-end",
                    padding: ".5em"          
                    }}>
                    <Button color="secondary" variant="contained" onClick={handleSubmit}>submit</Button> 
                </ButtonGroup>               
            </Paper>
            <Paper 
                elevation = {1}
                sx={{
                    padding: ".5em",
                    margin: "auto",
                    width: { 
                        xs: "100%", 
                        sm: "100%",
                        md: "100%",
                        lg: "50%"
                    }
            }}> 
                <Box sx={{
                    paddingBottom: ".5em"
                }}>
                    <Typography variant={"h3"}>Your categories</Typography>
                    <Typography variant={"caption"}>The table below provides an overview over your recurring transactions</Typography>
                </Box>
                <RecurringTransactionsTable/>  
            </Paper>
            
            <SimpleSnackbar 
                message={message}
                error={error}
            />   
        </Box>
    )
}
