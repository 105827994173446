import {createSlice} from '@reduxjs/toolkit'


const Accounts = createSlice({
    name: "accounts",
    initialState: {
        data: [],
        status: {
            error: null,
            message: null,
            loading: true,
        }
    },
    reducers: {


        setAccounts : (state, action) => {
            return {
                data : [...action.payload.data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        addAccount : (state, action) => {
            return {
                data : [...state.data, action.payload.data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        modifyAccount : (state, action) => {
            const data = state.data.map( account => {
                if (account.id === action.payload.data.id){
                    return action.payload.data;
                } else {
                    return account;
                }
            })

            return {
                data : [...data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }

        },


        deleteAccount : (state, action) => {
            const data = state.data.filter( account => {
                if (account.id === action.payload.data.id){
                    return false;
                } else {
                    return true;
                }
            })

            return {
                data : [...data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        setStatus : (state, action) => ({
            ...state,
            status: {
                error: action.payload.error,
                message: action.payload.message,
                loading: action.payload.loading,
            },
        }),

        resetStatus: (state) => {
            return {
                data: [...state.data],
                status : {
                    error: null,
                    message: null,
                    loading: false,
                }
            }
        }
    }
})



export const { addAccount, setAccounts, deleteAccount, modifyAccount, setStatus, resetStatus} = Accounts.actions;
export default Accounts.reducer;