import {Box} from '@mui/material'
import React from 'react'
import { BannerWithBox } from '../components/banner'
import PapperWithIcon from '../components/papper/PapperWithIcon'
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import { useDispatch } from 'react-redux'
import { signUp } from '../redux/services/User'
import { SimpleFooter } from '../components/footer';


export default function Home() {
  const dispatch = useDispatch();


  return (
  <Box sx={{
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column"
  }}>
      <BannerWithBox
        backgroundImage={"/images/PiggyBank.jpg"}
        header={"Get a handle over your personal economy today"}
        caption={"Start today and register an account for free"} 
        actionText={"Register"} 
        onClick={() => {dispatch(signUp())}}
      />

      <Box sx={{
        display: "flex",
        flexdirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,     
        backgroundColor: "thirdly.main" ,
        pt: 2
      }}>
        <PapperWithIcon 
          sx={{width:  { sm: "100%", md: '25%', lg: "20%"}}}
          header={"Accounts"} 
          caption={"Add your custom accounts and track the balance over time."} 
          icon={<CreditScoreRoundedIcon/>}
        />
        <PapperWithIcon 
          sx={{width:  { sm: "100%", md: '25%', lg: "20%"}}}
          header={"Categories"} 
          caption={"Create a budget and tag expenses using custom categories."} 
          icon={<LocalOfferRoundedIcon/>}
        />
        <PapperWithIcon 
          sx={{width:  { sm: "100%", md: '25%', lg: "20%"}}}
          header={"Analytics"} 
          caption={"Follow up on your expenditures through data driven insights."} 
          icon={<InsightsRoundedIcon/>}
        />
      </Box> 

      <SimpleFooter/>
  </Box>
  
  )
}


//signup