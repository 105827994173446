import {createSlice} from '@reduxjs/toolkit'


const Transactions = createSlice({
    name: "transactions",
    initialState: {
        data: [],
        status: {
            error: null,
            message: null,     
            loading: true,    
        }
    },

    reducers: {

        setTransactions : (state, action) => ({
            ...state,
            status: {
                ...state.status,
                ...action.payload.status
            },
            data: [...action.payload.data]
        }),

        addTransaction : (state, action) => ({
                status: {
                    ...state.status,
                    ...action.payload.status
                },
                data: [...state.data, action.payload.data]
        }),

        modifyTransaction : (state, action) => {
            const transactions =  state.data.map( transaction => {
                if (transaction.id === action.payload.data.id){
                    return action.payload.data;
                } 
                
                else {
                    return transaction;
                }
            })
            return {...state,
                status: {
                    ...state.status,
                    ...action.payload.status
                },
                data: [...transactions]}
        },

        deleteTransaction : (state, action) => {
            var transactions = state.data.filter( transaction => {
                if (transaction.id === action.payload.data){
                    return false
                } else {
                    return true
                }
               
            })
            return {
                status: {
                    ...state.status,
                    ...action.payload.status
                },
                data: [...transactions]}
        },

        setStatus : (state, action) => ({
            ...state,
            status: {
                error: action.payload.error,
                message: action.payload.message,
                loading: action.payload.loading,
            },
        }),

        resetStatus : (state, action) => {
            return {
                data : [...state.data],
                status: {
                    error: null,
                    message: null,
                    loading: false,
                }
            }
        }
    }
})



export const { setTransactions, addTransaction, modifyTransaction, deleteTransaction, resetStatus, setStatus} = Transactions.actions;
export default Transactions.reducer;