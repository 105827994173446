import React, { useState } from 'react'
import {
  Stack,
  Paper,
  Typography,
  Box
} from '@mui/material';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FormInputText,
  FormRadioButton,
  FormDatePicker,
  FormDropdown,
} from '../fields'
import { useDispatch, useSelector } from 'react-redux';
import store from '../../redux/store';

import { changeDatePeriod } from '../../redux/services/Settings';
import moment from 'moment';

const schema = yup.object({
  startDate :  yup.date().required('Initial date required').max(yup.ref('endDate'),"start date can't be after the end date"),
  endDate : yup.date().required('Initial date required').min(yup.ref('startDate'),"end date can't be before start date"),
  dateGranuality: yup.string("Wrong format").nullable().required("Required")
});


export default function ChartFilter() {

  const {endDate, startDate, dateGranuality} = store.getState().settings.data.filterOptions
  const dispatch = useDispatch();
  const { 
    control,
    trigger,
    watch,
    getValues,
    
  } = useForm({  
    mode: 'onChange',
    defaultValues: {
      startDate: moment(startDate),
      endDate: moment(endDate),
      dateGranuality: dateGranuality,
    },
    resolver: yupResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined
  });

  const watchAll = watch(["startDate", "endDate", "dateGranuality"])


  React.useEffect( () => (
    async () => {
      const errors = await trigger(["startDate","endDate", "dateGranuality"]);
      if(errors){
        const [startDate, endDate, dateGranuality] = getValues(["startDate","endDate","dateGranuality"])
        dispatch(changeDatePeriod({
          startDate: startDate,
          endDate: endDate, 
          dateGranuality: dateGranuality
        }));
      }


    }),[watchAll])
  
  return (
    <Paper>
        <Typography>Filter</Typography>
        <Stack direction="row">
          <FormDatePicker
            name={"startDate"} 
            control={control} 
            label={"Start of period"}
          />
          <FormDatePicker
            name={"endDate"} 
            control={control} 
            label={"End of period"}
          />
          <FormDropdown 
            name={"dateGranuality"} 
            control={control} 
            label={"Date granuality"} 
            options={[
              {label: "Year", value: "year"},
              {label: "Month", value: "month"},
              {label: "Week", value: "week"},
              {label: "Day", value: "day"},
            ]}/>
        </Stack>
    </Paper>
  )
}
