import React from 'react'

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Stack,
    Paper,
} from '@mui/material';

import {
    FormInputText,
} from '../fields'

const schema = yup.object({
    name: yup.string().required("Fisrt name is required"),
    description: yup.string().nullable(true),
    balance: yup.number().required("Balance is required").positive("Balance need to be positive"),
  });


const AccountForm = React.forwardRef(({handleSubmitCallback, defaultValues}, ref) =>  {

    const { handleSubmit, control, reset} = useForm({
        defaultValues: defaultValues, 
        resolver: yupResolver(schema),
        shouldFocusError: true,
        shouldUnregister: false, 
    });

    React.useEffect(() => {
        // reset form with user data
        reset(defaultValues);
    }, [defaultValues, reset]);

    
    return (
            <Paper elevation={0} >
                <form onSubmit={handleSubmit(handleSubmitCallback)} ref={ref}>

                    <Stack gap={2}>

                        <FormInputText 
                            name={"name"} 
                            control={control} 
                            label={"Name"}/>

                        <FormInputText 
                            name={"description"} 
                            control={control} 
                            label={"Description"}/>

                        <FormInputText 
                            name={"balance"} 
                            control={control} 
                            label={"Current balance"}/>
                    </Stack>
                    
                </form>
            </Paper>
    );
});

export default AccountForm;