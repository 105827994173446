import api from "./Axios";

import { 
    setAccounts as setAccountsReducer, 
    addAccount as addAccountReducer, 
    deleteAccount as deleteAccountReducer, 
    modifyAccount as modifyAccountReducer, 
    resetStatus,
    setStatus 
} from "../reducers/Accounts";

import { setTransactions } from "../reducers/Transactions";


function getAccounts() { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "get",
            url: "accounts",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`}
        }

        api(request)

        // Dispatch: add an account 
        .then( (response) => {

            const payload = {
                data: response.data.map( account => ({
                    balance: account.balance,
                    createdDate: account.created_date,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modified_at,
                    name: account.name,
                    userId: account.user_id   
                })),
                status: {
                    error: false,
                    messange: "Successfully retrieved accounts"
                }
            }

            dispatch(setAccountsReducer(payload))
        })

        // Distpatch: modify status
        .catch( (error) => {
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Dispatch: reset status
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function addAccount(payload) { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "POST",
            url: "accounts",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                balance: payload.balance,
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch: add an account 
        .then( (response) => {

            const payload = {
                data: response.data.map( account => ({
                    balance: account.balance,
                    createdDate: account.created_date,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modified_at,
                    name: account.name,
                    userId: account.user_id   
                }))[0],
                status: {
                    error: false,
                    message: "Successfully added an account"
                }
            }

            dispatch(addAccountReducer(payload))
            return payload.data
        })

        // Distpatch: modify status
        .catch( ({response}) => {

            const payload = {
                error: true,
                message: response.data.message
            }

            dispatch(setStatus(payload))
        })

        // Dispatch: reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function editAccount(payload) { 
    return async (dispatch, getState ) => {
        const {user, transactions, recurringTransactions} = getState()

        const request = {
            method: "PUT",
            url: `accounts/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                balance: payload.balance,
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch: add an account 
        .then( (response) => {

            const payload = {
                data: response.data.map( account => ({
                    balance: account.balance,
                    createdDate: account.created_date,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modified_at,
                    name: account.name,
                    userId: account.user_id   
                }))[0],
                status: {
                    error: false,
                    message: "Successfully modified an account"
                }
            }

            dispatch(modifyAccountReducer(payload))
            return payload.data
        })

        // Dispatch: modify transactions
        .then( response => {
            
            const payload = {
                data : transactions.data.map( transaction => {
                    if(transaction.account.id === response.id){
                        return ({
                            ...transaction,
                            account: {
                                name: response.name,
                                id: response.id,
                                description: response.description
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    error: false,
                    message: "Sussessfully modified transactions"
                }
            }

            dispatch(setTransactions(payload))
            return response;

        })

        // Dispatch: modify recurring transaction
        .then( response => {
            
            const payload = {
                data : recurringTransactions.data.map( transaction => {
                    if(transaction.account.id === response.id){
                        return ({
                            ...transaction,
                            account: {
                                name: response.name,
                                id: response.id,
                                description: response.description
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    error: false,
                    message: "Sussessfully modified transactions"
                }
            }

            dispatch(setTransactions(payload))

        })

        // Distpatch: modify status
        .catch( (error) => {
            
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Dispatch: reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function deleteAccount(payload) { 
    return async (dispatch, getState ) => {
        const {user, transactions, recurringTransactions} = getState()

        const request = {
            method: "DELETE",
            url: `accounts/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch: add an account 
        .then( (response) => {
            const payload = {
                data: {
                    id: response.data[0].id
                },
                status: {
                    error: false,
                    message: "Successfully deleted an account"
                }
            }

            dispatch(deleteAccountReducer(payload))
            return payload.data
        })

        // Dispatch: modify transactions
        .then( response => {
            
            const payload = {
                data: transactions.data.filter( transaction => (transaction.account.id !== response.id)),
                status: {
                    error: false,
                    message: "Successfully modified transactions"
                }
            }

            dispatch(setTransactions(payload))
            return response
        })
        
        // Dispatch: modify recurring transactions
        .then( response => {
            
            const payload = {
                data : recurringTransactions.data.map( transaction => {
                    if(transaction.account.id === response.id){
                        return ({
                            ...transaction,
                            account: {
                                name: response.name,
                                id: response.id,
                                description: response.description
                            }       
                        });
                    }
                    else {
                        return ({
                            ...transaction,
                        });
                    }
                }),
                status: {
                    error: false,
                    message: "Sussessfully modified transactions"
                }
            }

            dispatch(setTransactions(payload))
            return response;

        })

        // Distpatch: modify status
        .catch( (error) => {
  
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Dispatch: reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}




export {getAccounts, addAccount, editAccount, deleteAccount}