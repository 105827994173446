import React from 'react'
import {Route, BrowserRouter as Router,Routes} from "react-router-dom";
import RAUTES, {ProtectedRoute} from './routes'
import { NavBar } from './components/navbar';
import AuthWrapper from './components/auth/AuthWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts } from './redux/services/Accounts';
import { getTransactions } from './redux/services/Transactions';
import { getCategories } from './redux/services/Categories';
import { getRecurringTransactions } from './redux/services/RecurringTransactions';



function App() {
  let {loggedIn, emailVerified} = useSelector( store => store.user.data.authorization);
  const dispatch = useDispatch();

  React.useEffect( () => {
    if(loggedIn && emailVerified){
      dispatch(getAccounts())
      dispatch(getTransactions())
      dispatch(getCategories())
      dispatch(getRecurringTransactions())
    }
  })

  return (
      <Router>
        <AuthWrapper>
          <NavBar/>
          <Routes>
              {RAUTES.map( ({path, isPrivate, component}, idx) => (
                <Route
                  path={path}
                  element={<ProtectedRoute isPrivate={isPrivate} component={component}/>} 
                  key={idx}/>          
              ))}
          </Routes>     
        </AuthWrapper>
      </Router> 
  );

}

export default App;
