import React from 'react'
import {TableContainer
        ,Table
        ,TableHead
        ,TableBody
        ,TableRow
        ,TableCell
        ,Paper
        ,Button
    } from "@mui/material"

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';



export default function RecurringTransactionsTable() {

    const transactions = useSelector((store) => store.recurringTransactions.data)
    const navigate = useNavigate();

    const handleClick = (e)=>{
        navigate(`/transactions/recurring/${e.currentTarget.value}`)
    }

    return (
        <TableContainer component={Paper} sx={{height: "100%"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map( (transaction, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{transaction["name"]}</TableCell>
                            <TableCell>{transaction["description"]}</TableCell>
                            <TableCell>{transaction["amount"]}</TableCell>
                            <TableCell>
                                <Button 
                                    value={transaction["id"]} 
                                    onClick={handleClick}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                
            </Table>
        </TableContainer>
    )
}
