import React from "react";
import { FormControl, FormLabel, FormHelperText} from "@mui/material";
import { Controller } from "react-hook-form";


import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import 'moment/locale/en-gb'


const FormDatePicker = ({ name, control, label, rules, errors}) => {

  return (
    

      <Controller 
        sx={{background: "red"}}
        control={control}
        name={name}
        rules={rules}
        errors={errors}
        label={label}
        render={({ field: { onChange, value }, fieldState: {error}}) => (
          <FormControl 
            size={"large"} 
            variant={"outlined"} 
            fullWidth 
            className="date-picker" 
            sx={(theme) => ({   
              ".date-picker input" : {
                width: "100%",
                boxSizing: "border-box",
                fontSize: theme.typography.body1,
                }
            })}>
              <FormLabel component="legend">{label}</FormLabel>
              <FormHelperText error={error ? true : false}> 
                {error ? error.message : ""}
              </FormHelperText>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en_GB"}>
                <DesktopDatePicker
                    label="Date"
                    inputFormat="YY/MM/DD"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>

          </FormControl>
        )}
      />
    

  );
};

export default FormDatePicker;