import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import React from 'react';

const ProtectedRoute = ({ component: Component, isPrivate}) => {
    const {loggedIn, emailVerified} = useSelector((state) => state.user.data.authorization)

    if(isPrivate && !Boolean(loggedIn) && !Boolean(emailVerified)){
      return <Navigate to="/"/>
    } 
    return <Component/>

  };


  export default ProtectedRoute;