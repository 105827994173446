import React from "react";
import { FormControl, FormLabel, FormHelperText, MenuItem, Select } from "@mui/material";
import {Controller} from "react-hook-form";



const FormDropdown = ({name,control, label, options}) => {


  return (
        <Controller
          control={control}
          name={name}
          render={({
            field: { onChange, onBlur, value},
            fieldState: { error },
          }) => (
            <FormControl size={"large"} variant={"outlined"} fullWidth>
              <FormLabel component="legend">{label}</FormLabel>
              <FormHelperText error={error ? true : false}> 
                {error ? error.message : ""}
              </FormHelperText>
              <Select onChange={onChange} value={value || ""}>
                {options.map( option => (
                  <MenuItem 
                    key={option.value} 
                    value={option.value}
                    onBlur={onBlur}
                    >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
    
  )
};

export default FormDropdown