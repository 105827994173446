import api from "./Axios";
import { 
    addRecurringTransactions as addTransactionReducer, 
    setRecurringTransactions as setTransactionsReducer, 
    deleteRecurringTransactions as deleteTransactionsReducer, 
    modifyRecurringTransactions as modifyTransactionReducer, 
    setStatus,
    resetStatus } from "../reducers/RecurringTransactions";



// Done
function getRecurringTransactions() { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "get",
            url: "transactions/recurring",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`}
        }

        api(request)

        // Dispatch data
        .then( (response) => {
            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionType: transaction.transaction_type,
                    startDate: transaction.start_date,
                    endDate: transaction.end_date,
                    period:  transaction.period,
                    name: transaction.name,

                    account: {
                        id: transaction.account_id,
                        name: transaction.account_name,
                        description: transaction.account_description,
                        balance: transaction.balance
                    },
                    category: {
                        id: transaction.category_id,
                        name: transaction.category_name,
                        description: transaction.category_description,   
                        estimate: transaction.category_estimate,  
                        categoryType: transaction.category_type             
                    }
                })),
                status: {
                    error: false,
                    message: "Successfully retrieved categories"
                }
            }
            
            dispatch(setTransactionsReducer(payload))
        })

        // Distpatch error
        .catch( (error) => {
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}


// Done
function addRecurringTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user, accounts, categories} = getState()
        const account = accounts.data.filter( account => (account.id === payload.accountId))[0]
        const category = categories.data.filter( category => (category.id === payload.categoryId))[0]

        const request = {
            method: "POST",
            url: "transactions/recurring",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                amount: (payload.transactionType === "expenditure") 
                    ? ( -1 * Math.abs(payload.amount)) 
                    : (payload.transactionType === "income") 
                    ? ( +1 * Math.abs(payload.amount)) 
                    : payload.amount,
                transactionType: payload.transactionType,
                accountId: payload.accountId,
                categoryId: payload.categoryId,
                startDate: payload.startDate,
                endDate: payload.endDate,
                period: payload.period,   
            }
        }
        
        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    name: transaction.name,
                    startDate: transaction.start_date,
                    endDate: transaction.end_date,
                    period: transaction.period,  
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionType: transaction.transaction_type,
                    account: {
                        id: account.id,
                        name: account.name,
                        description: account.description,
                        balance: account.balance
                    },
                    category: {
                        id: category.id,
                        name: category.name,
                        description: category.description, 
                        estimate: category.estimate,  
                        categoryType: category.categoryType                  
                    }
                }))[0],
                status: {
                    error: false,
                    message: "Successfully added recurring transaction"
                }
            }

            dispatch(addTransactionReducer(payload))
        })

        // Distpatch error
        .catch( (error) => {
            const payload = {
                error: true,
                message: error.message
            }
            
            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}


function editRecurringTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user, accounts, categories} = getState()
        const account = accounts.data.filter( account => (account.id === payload.accountId))[0]
        const category = categories.data.filter( category => (category.id === payload.categoryId))[0]

        const request = {
            method: "PUT",
            url: `transactions/recurring/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                name: payload.name,
                description: payload.description,
                amount: (payload.transactionType === "expenditure") 
                    ? ( -1 * Math.abs(payload.amount)) 
                    : (payload.transactionType === "income") 
                    ? ( +1 * Math.abs(payload.amount)) 
                    : payload.amount,
                transactionType: payload.transactionType,
                accountId: payload.accountId,
                categoryId: payload.categoryId,
                startDate: payload.startDate,
                endDate: payload.endDate,
                period: payload.period,   
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {
            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    name: transaction.name,
                    startDate: transaction.start_date,
                    endDate: transaction.end_date,
                    period: transaction.period,  
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionType: transaction.transaction_type,
                    account: {
                        id: account.id,
                        name: account.name,
                        description: account.description,
                        balance: account.balance
                    },
                    category: {
                        id: category.id,
                        name: category.name,
                        description: category.description, 
                        estimate: category.estimate,  
                        categoryType: category.categoryType                  
                    }
                }))[0],
                status: {
                    error: false,
                    message: "Successfully modified recurring transaction"
                }
            }

            dispatch(modifyTransactionReducer(payload))
        })

        // Distpatch error
        .catch( (error) => {
            const payload = {
                error: true,
                message: error.message
            }
            
            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })
}}

function deleteRecurringTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "DELETE",
            url: `transactions/recurring/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
        }

        api(request)

        // Dispatch data
        .then( (response) => {
            const payload = {
                data: response.data[0].id,
                status: {
                    error: false,
                    message: "Successfully deleted a recurring transaction"
                }
            }

            dispatch(deleteTransactionsReducer(payload))
            return payload.data;
        })

        // Distpatch error
        .catch( (error) => {
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })
}}


export {getRecurringTransactions, addRecurringTransaction, editRecurringTransaction, deleteRecurringTransaction}