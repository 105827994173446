import React from "react";
import { Controller } from "react-hook-form";
import { 
    FormHelperText, 
    FormControl, 
    FormLabel, 
    TextField} from "@mui/material";



const FormInputText  = ({ name, control, label, ...props }) => {

    const {
        disabled
        ,multiline
    } = props
    
    return (
            <Controller
                name={name}
                control={control}
                render={({ 
                    field: { onChange, value }, 
                    fieldState: {error}
                }) => (
                    <FormControl size={"large"} variant={"outlined"} fullWidth>
                        <FormLabel component="legend">{label}</FormLabel>
                        <FormHelperText error={error ? true : false}> 
                            {error ? error.message : ""}
                        </FormHelperText>
                        <TextField   
                            disabled={disabled || false} 
                            multiline={multiline || true}
                            onChange={onChange} 
                            value={value || ""} />
                    </FormControl>
                )}
            />        
    )
}

export default FormInputText;
