import axios from 'axios';
import CryptoJS from 'crypto-js';
import cryptoRandomString from 'crypto-random-string';
import jwt_decode from "jwt-decode";


import { login as loginReducer, resetStatus, setStatus } from '../reducers/User';


async function getVerifier(length=63) {
    const str = cryptoRandomString({length: length})
      .toString(CryptoJS.enc.Base64).replace(/[^A-Za-z0-9]/, '')
    return str
}

async function getChallange(verifier) {
    let str = CryptoJS.SHA256(verifier).toString(CryptoJS.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    return str
}



function loginWithPopup(){
    return async (_, getState ) => {

        const codeVerifier = await getVerifier();
        const state = await getVerifier();
        const challange = await getChallange(codeVerifier)

        localStorage.clear()
        localStorage.setItem(state, JSON.stringify({
            "codeVerifier" : codeVerifier,
            "return_uri" : window.location.pathname
        }))


        window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?`
            + `audience=${process.env.REACT_APP_AUTH0_AUDIENCE}&`
            + `scope=${process.env.REACT_APP_AUTH0_SCOPE}&`
            + `response_type=${process.env.REACT_APP_AUTH0_RESPONSE_TYPE}&`
            + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&`
            + `redirect_uri=${window.location.origin}&`
            + `state=${state}&`
            + `code_challenge=${challange}&`
            + `response_mode=query&`
            + `code_challenge_method=${process.env.REACT_APP_AUTH0_CODE_CHALLANGE_METHOD}`
}}

function signUp() {
    return async (_, getState ) => {

        const codeVerifier = await getVerifier();
        const state = await getVerifier();

        localStorage.clear()
        localStorage.setItem(state, JSON.stringify({
            "codeVerifier" : codeVerifier,
        }))


        window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?`
            + `audience=${process.env.REACT_APP_AUTH0_AUDIENCE}&`
            + `scope=${process.env.REACT_APP_AUTH0_SCOPE}&`
            + `response_type=${process.env.REACT_APP_AUTH0_RESPONSE_TYPE}&`
            + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&`
            + `redirect_uri=${process.env.REACT_APP_HOST}verify&`
            + `state=${state}&`
            + `screen_hint=signup&`
}}


function logout() {
    return async (dispatch, getState ) => {
        window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?`
            + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&`
            + `returnTo=${window.location.origin}&`
}}


function silentAuthentication(){
    return async (dispatch, getState ) => {
        const codeVerifier = await getVerifier();
        const state = await getVerifier();

        localStorage.clear()
        localStorage.setItem(state, JSON.stringify({
            "codeVerifier" : codeVerifier,
            "return_uri" : window.location.pathname
        }))

        const challange = await getChallange(codeVerifier)
        
        window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?`
        + `audience=${process.env.REACT_APP_AUTH0_AUDIENCE}&`
        + `scope=${process.env.REACT_APP_AUTH0_SCOPE}&`
        + `response_type=${process.env.REACT_APP_AUTH0_RESPONSE_TYPE}&`
        + `client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&`
        + `redirect_uri=${window.location.origin}&`
        + `response_mode=query&`
        + `state=${state}&`
        + `code_challenge=${challange}&`
        + `code_challenge_method=${process.env.REACT_APP_AUTH0_CODE_CHALLANGE_METHOD}&`
        + `prompt=none`
}}


function getAccessToken(code, state){
    return async (dispatch, getState ) => {
        const appState = JSON.parse(localStorage.getItem(state))

        axios({
            method: "POST",
            url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
            data: {
                grant_type: "authorization_code",
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                response_mode: "query",
                code: code,
                code_verifier: appState.codeVerifier,
                redirect_uri: window.location.origin
            }
        })

         // Dispatch data 
         .then( ({data}) => {

            const user = jwt_decode(data.id_token)

            const payload = {
                data: {
                    tokens: {
                        access: data.access_token,
                        id: data.id_token,
                        refresh: data.refresh_token
                    },
                    authorization: {
                        emailVerified: user.email_verified,
                        loggedIn: true,
                    },
                    user: {
                        nickname: user.nickname,
                        email: user.email,
                        id: user.sub
                    }
                },
                status: {},
            }
            
            dispatch(loginReducer(payload))

         })

        // Error encountered when trying to authenticate
        .catch( (error) => {
         
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))

        })

        // Resetting status
        .finally( () => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
            localStorage.removeItem(state)
        })

    }
}




export {loginWithPopup, getAccessToken, silentAuthentication, logout, signUp}
