import {createSlice} from '@reduxjs/toolkit'



const User = createSlice({
    name: "user",

    initialState: {
        data: {
            tokens: {
                access: null,
                refresh: null,
                id: null
            },
            authorization: {
                emailVerified: null,
                loggedIn: false,
            },
            user: {
                email: null
            }
        },

        status: {
            error: null,
            message: null
        }
    },  

    reducers: {
        login : (state, action) => {
            return {
                data: {
                    tokens: {
                       ...state.data.tokens,
                       ...action.payload.data.tokens
                    },
                    authorization: {
                        ...state.data.authorization,
                        emailVerified: action.payload.data.authorization.emailVerified,
                        loggedIn: true,
                    },
                    user: {
                        ...state.data.user,
                        email: action.payload.data.user.email,
                        nickname: action.payload.data.user.nickname,
                        id: action.payload.data.user.id
                    }
                },
                status: {
                    ...state.status,
                },
            }
        },

        resetStatus : (state, action) => {
            return {
                ...state,
                status : {
                    error: null,
                    message: null
                }
            }
        },

        setStatus: (state, action) => {
            return {
                ...state,
                status : {
                    ...state,
                    ...action.payload.status
                }
            }
        }
    }
})

export const {setUser, login, setStatus, resetStatus} = User.actions;
export default User.reducer;