import {Account, Home, Accounts, Categories, Category, Transaction, Transactions, Dashbaord, VerifyAccount, RecurringTransactions,RecurringTransaction} from '../views'
import ProtectedRoute from './ProtectedRoute'; 

const ROUTES = [
    {
        path: "/",
        isPrivate: false,
        component: Home,
        verbose: "home",
        includedInNav: false
    },

    {
        path: "/accounts/:accountId",
        isPrivate: true,
        component: Account,
        verbose: "Account",
        includedInNav: false
    },

    {
        path: "/accounts",
        isPrivate: true,
        component: Accounts,
        verbose: "Accounts",
        includedInNav: true
    },

    {
        path: "/categories",
        isPrivate: true,
        component: Categories,
        verbose: "Categories",
        includedInNav: true
    },

    {
        path: "/categories/:categoryId",
        isPrivate: true,
        component: Category,
        verbose: "Category",
        includedInNav: false
    },
    {
        path: "/transactions",
        isPrivate: true,
        component: Transactions,
        verbose: "Transactions",
        includedInNav: true
    },

    {
        path: "/transactions/recurring",
        isPrivate: true,
        component: RecurringTransactions,
        verbose: "Recuring",
        includedInNav: true      
    },

    {
        path: "/transactions/recurring/:transactionId",
        isPrivate: true,
        component: RecurringTransaction,
        verbose: "Recuring",
        includedInNav: false      
    },

    {
        path: "/transactions/:transactionId",
        isPrivate: true,
        component: Transaction,
        verbose: "Transaction",
        includedInNav: false
    },

    {
        path: "/dahsboard",
        isPrivate: true,
        component: Dashbaord,
        verbose: "Home",
        includedInNav: true
    },

    {
        path: "/verify",
        isPrivate: false,
        component: VerifyAccount,
        verbose: "Verification page",
        includedInNav: false      
    }

]


export default ROUTES;
export {ProtectedRoute};