import {createSlice} from '@reduxjs/toolkit'


const Categories = createSlice({
    name: "categories",
    initialState: {
        data: [],
        status: {
            error: null,
            message: null,
            loading: true,
        }
    },
    reducers: {

        setCategories : (state, action) => {
            return {
                data : [...action.payload.data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        addCategory : (state, action) => {
            return {
                data : [...state.data, action.payload.data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        modifyCategory : (state, action) => {
            const data = state.data.map( category => {
                if (category.id === action.payload.data.id){
                    return action.payload.data;
                } else {
                    return category;
                }
            })

            return {
                data : [...data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }

        },

        deleteCategory : (state, action) => {
            const data = state.data.filter( category => {
                if (category.id === action.payload.data.id){
                    return false;
                } else {
                    return true;
                }
            })

            return {
                data : [...data],
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        setStatus : (state, action) => ({
            ...state,
            status: {
                error: action.payload.error,
                message: action.payload.message,
                loading: action.payload.loading,
            },
        }),

        resetStatus: (state) => {
            return {
                data: [...state.data],
                status : {
                    error: null,
                    message: null,
                    loading: false,
                }
            }
        }
    }
})



export const { setCategories, addCategory, modifyCategory, deleteCategory, setStatus, resetStatus} = Categories.actions;
export default Categories.reducer;