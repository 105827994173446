import {createSlice} from '@reduxjs/toolkit'
import moment from "moment";
import { getDatesForPeriod} from "../../util/date";

const Settings = createSlice({
    name: "categories",
    initialState: {
        data: {
            filterOptions: {
                startDate: moment().subtract(1, "month").format("YYYY-MM-DD"), 
                endDate: moment().format("YYYY-MM-DD"),
                dateGranuality: "month",
                datePeriod: getDatesForPeriod(),
                categories: [],
                accounts: [],
            }
        },
        status: {
            error: null,
            message: null,
            loading: false,
        }
    },
    reducers: {

        setFilterOptions : (state, action) => {
            return {
                data : {
                    ...state,
                    filterOptions: {
                        ...state.data.filterOptions,
                        ...action.payload.filterOptions
                    },
                },
                status: {
                    ...state.status,
                    ...action.payload.status
                }
            }
        },

        setStatus : (state, action) => ({
            ...state,
            status: {
                error: action.payload.error,
                message: action.payload.message,
                loading: action.payload.loading
            },
        }),

        resetStatus: (state) => {
            return {
                data: {...state.data},
                status : {
                    error: null,
                    message: null,
                    loading: false,
                }
            }
        }
    }
})



export const {setFilterOptions, setStatus, resetStatus} = Settings.actions;
export default Settings.reducer;