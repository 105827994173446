import React from 'react'
import {TableContainer
        ,Table
        ,TableHead
        ,TableBody
        ,TableRow
        ,TableCell
        ,Paper
        ,Button
    } from "@mui/material"

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';


export default function CategoryTable() {

    const categories = useSelector((state) => state.categories.data)
    const navigate = useNavigate();

    const handleClick = (e)=>{
        navigate(`/categories/${e.currentTarget.value}`)
    }

    return (
        <TableContainer component={Paper} sx={{height: "100%"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Monthly estimate</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map( (category, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{category["name"]}</TableCell>
                            <TableCell>{category["description"]}</TableCell>
                            <TableCell>{category["estimate"]}</TableCell>
                            <TableCell>
                                <Button 
                                    value={category["id"]} 
                                    onClick={handleClick}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                
            </Table>
        </TableContainer>
    )
}
