import api from "./Axios";
import { 
    addTransaction as addTransactionReducer, 
    setTransactions as setTransactionsReducer, 
    deleteTransaction as deleteTransactionsReducer, 
    modifyTransaction as modifyTransactionReducer, 
    setStatus,
    resetStatus } from "../reducers/Transactions";

import {modifyAccount} from "../reducers/Accounts";



function getTransactions() { 
    return async (dispatch, getState ) => {
        const {user} = getState()

        const request = {
            method: "get",
            url: "transactions",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`}
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {
            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionDate: transaction.transaction_date,
                    transactionType: transaction.transaction_type,
                    account: {
                        id: transaction.account_id,
                        name: transaction.account_name,
                        description: transaction.account_description,
                        balance: transaction.balance
                    },
                    category: {
                        id: transaction.category_id,
                        name: transaction.category_name,
                        description: transaction.category_description,   
                        estimate: transaction.category_estimate,  
                        categoryType: transaction.category_type             
                    }
                })),
                status: {
                    error: false,
                    message: "Successfully retrieved categories"
                }
            }
            dispatch(setTransactionsReducer(payload))
        })

        // Distpatch error
        .catch( (error) => {

            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function addTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user, accounts, categories} = getState()
        const account = accounts.data.filter( account => (account.id === payload.accountId))[0]
        const category = categories.data.filter( category => (category.id === payload.categoryId))[0]

        const request = {
            method: "post",
            url: "transactions",
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                transactionDate: payload.transactionDate,
                description: payload.description,
                amount: (payload.transactionType === "expenditure") 
                    ? ( -1 * Math.abs(payload.amount)) 
                    : (payload.transactionType === "income") 
                    ? ( +1 * Math.abs(payload.amount)) 
                    : payload.amount,
                transactionType: payload.transactionType,
                accountId: payload.accountId,
                categoryId: payload.categoryId

                
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionDate: transaction.transaction_date,
                    transactionType: transaction.transaction_type,
                    account: {
                        id: account.id,
                        name: account.name,
                        description: account.description,
                        balance: account.balance
                    },
                    category: {
                        id: category.id,
                        name: category.name,
                        description: category.description, 
                        estimate: category.estimate,  
                        categoryType: category.categoryType                  
                    }
                }))[0],
                status: {
                    error: false,
                    message: "Successfully added transactions"
                }
            }

            dispatch(addTransactionReducer(payload))
            return payload.data
        })

        // Distpatch modified account data
        .then( (response) => {
            
            // Modify account based on transaction
            const payload = {
                data: {
                    createdDate: account.createdDate,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modifiedAt,
                    name: account.name,
                    userId: account.userId, 
                    balance: (parseInt(account.balance) + parseInt(response.amount))
                },
                status: {
                    message: "Successfully updated account",
                    error: false
                }
            }

            dispatch(modifyAccount(payload))
        })   

        // Distpatch error
        .catch( ({response}) => {

            const payload = {
                error: true,
                message: response.data.message
            }
            

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function editTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user, categories, accounts, transactions} = getState()

        const account = accounts.data.filter( account => (account.id === payload.accountId))[0]
        const category = categories.data.filter( category => (category.id === payload.categoryId))[0]
        const transaction = transactions.data.filter( transaction => (transaction.id === payload.id))[0]

        const request = {
            method: "PUT",
            url: `transactions/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
            data: {
                transactionDate: payload.transactionDate,
                description: payload.description,
                amount: (payload.transactionType === "expenditure") 
                    ? ( -1 * Math.abs(payload.amount)) 
                    : (payload.transactionType === "income") 
                    ? ( +1 * Math.abs(payload.amount)) 
                    : payload.amount,
                transactionType: payload.transactionType,
                accountId: payload.accountId,
                categoryId: payload.categoryId
            }
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {

            const payload = {
                data: response.data.map( (transaction) => ({
                    id: transaction.id,
                    description: transaction.description,
                    amount: transaction.amount,
                    transactionDate: transaction.transaction_date,
                    transactionType: transaction.transaction_type,
                    account: {
                        id: account.id,
                        name: account.name,
                        description: account.description,
                        balance: account.balance
                    },
                    category: {
                        id: category.id,
                        name: category.name,
                        description: category.description,   
                        estimate: category.estimate,  
                        categoryType: category.categoryType                   
                    }
                }))[0],
                status: {
                    error: false,
                    message: "Successfully modified a transactions"
                }
            }

            dispatch(modifyTransactionReducer(payload))
            return payload.data;
        })

        // Distpatch modified account data
        .then( (response) => {
            // Modify account based on transaction
            const payload = {
                data: {
                    createdDate: account.createdDate,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modifiedAt,
                    name: account.name,
                    userId: account.userId, 
                    balance: (parseInt(account.balance) + (parseInt(response.amount) - parseInt(transaction.amount)))
                },
                status: {
                    message: "Successfully updated account",
                    error: false
                }
            }

            dispatch(modifyAccount(payload))

        })   

        // Distpatch error
        .catch( ({response}) => {
            
            const payload = {
                error: true,
                message: response.data.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}

function deleteTransaction(payload) { 
    return async (dispatch, getState ) => {
        const {user, accounts, transactions} = getState()

        const account = accounts.data.filter( account => (account.id === payload.accountId))[0]
        const transaction = transactions.data.filter( transaction => (transaction.id === payload.id))[0]


        const request = {
            method: "DELETE",
            url: `transactions/${payload.id}`,
            headers: { "Authorization": `Bearer ${user.data.tokens.access}`},
        }

        dispatch(setStatus({loading: true}))

        api(request)

        // Dispatch data
        .then( (response) => {
            const payload = {
                data: response.data[0].id,
                status: {
                    error: false,
                    message: "Successfully modified a transactions"
                }
            }

            dispatch(deleteTransactionsReducer(payload))
            return payload.data;
        })

        // Distpatch modified account data
        .then( () => {
            // Modify account based on transaction
            const payload = {
                data: {
                    createdDate: account.createdDate,
                    description: account.description,
                    id: account.id,
                    modifiedAt: account.modifiedAt,
                    name: account.name,
                    userId: account.userId, 
                    balance: (parseInt(account.balance) - parseInt(transaction.amount))
                },
                status: {
                    message: "Successfully updated account",
                    error: false
                }
            }

            dispatch(modifyAccount(payload))

        })   

        // Distpatch error
        .catch( (error) => {
            
            const payload = {
                error: true,
                message: error.message
            }

            dispatch(setStatus(payload))
        })

        // Reset status 
        .finally( (_) => {
            setTimeout(() => {
                dispatch(resetStatus())
            }, 3000)
        })

}}


export {getTransactions, deleteTransaction, addTransaction, editTransaction}