import React from 'react'
import MoneyPerCategoryLeft from '../../components/charts/MoneyPerCategoryLeft'
import ExpenditurePerCategory from '../../components/charts/ExpenditurePerCategory' 
import ChartFilter from '../../components/charts/ChartFilter'
import { useSelector } from 'react-redux'
import { Box} from '@mui/system'

export default function Dashbaord() {

  const transactionLoading = useSelector(state => state.transactions.status.loading);
  const categoriesLoading= useSelector(state => state.categories.status.loading);
  const accountsLoading = useSelector(state => state.accounts.status.loading);

  
  return (
    <div style={{
      boxSizing: "border-box",
      width: "100%",
      padding: "10px",
    }}>
      <ChartFilter/>
      <Box className={"container"} sx={{
        boxSizing: "border-box",
        p: 3,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        maxHeight: "500px",
        gap: 1,
      }}
      >
        {(!transactionLoading) && <ExpenditurePerCategory/>}
        {(!transactionLoading) && <MoneyPerCategoryLeft/>}
      </Box>
    </div>
  )
}
