import { configureStore } from '@reduxjs/toolkit';

import User from './reducers/User';
import accounts from './reducers/Accounts';
import categories from './reducers/Categories';
import transactions from './reducers/Transactions';
import Settings from './reducers/Settings';
import RecurringTransactions from './reducers/RecurringTransactions';



const store = configureStore({
    reducer: {
        user: User,
        transactions: transactions,
        accounts: accounts,
        categories: categories,
        settings: Settings,
        recurringTransactions: RecurringTransactions
    },
  devTools: true,
  });

export default store;
